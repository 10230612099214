import { React, useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faUpload, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import { Collapse } from 'react-collapse';
import { Tooltip } from 'react-tooltip'
import { Snackbar, Box } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { Alert } from '@mui/material';



const Attachments = (props) => {

    const { taskId, formMode, uploadedBy, childData } = props;
    const [selectedfile, SetSelectedFile] = useState([]);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [attachmentData, setAttachmentData] = useState([]);
    const [isCollapseOpen, setCollapseOpen] = useState(false);
    const [isFileCollapseOpen, setFileCollapseOpen] = useState(true);
    const [pageLoading, setPageLoading] = useState(false);
    const confirm = useConfirm();

    useEffect(() => {
        setPageLoading(true);
        if (formMode !== 'add') {
            getAttachmentGridData(taskId);
        }

    }, [])

    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    const getAttachmentGridData = async (taskId) => {
        let data = []

        try {
            data = await axios.get(Endpoints.getAttachmentDetails + parseInt(taskId)).then((response) => {
                return response.data;
            });
        } catch (err) {
            console.error(err);
        }

        setAttachmentData(data);
        setPageLoading(false);
    }

    const downloadFile = async (id) => {
        let downloadData = "";
        try {
            setSuccessMessage(`File will download shortly`);
            setOpenSnack(true);
            downloadData = await axios.get(Endpoints.downloadAttachment + parseInt(id)).then((response) => {
                return response.data;
            });
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement('a');
        const result = attachmentData.filter((data) => data.id === id);
        link.href = downloadData;
        link.setAttribute('download', result[0].fileName);
        link.click();
    }
    const deleteFile = async (id) => {
        confirm({ description: `This will permanently delete attachment.` })
            .then(async () => {
                let data = "";
                try {
                    data = await axios.post(Endpoints.deleteAttachment + parseInt(id)).then((response) => {
                        return response.data;

                    });
                } catch (err) {
                    console.error(err);
                }

                if (data == 'success') {
                    setPageLoading(true);
                    getAttachmentGridData(taskId);
                }

            }).catch(() => {});
    };
    //const deleteFile = async (id) => {
    //    let data = "";
    //    try {
    //        data = await axios.post(Endpoints.deleteAttachment + parseInt(id)).then((response) => {
    //            return response.data;
                
    //        });
    //    } catch (err) {
    //        console.error(err);
    //    }

    //    if (data == 'success') {
    //        setPageLoading(true);
    //        getAttachmentGridData(taskId);
    //    }
    //}

    const closePopup = () => {
        history.push('/');
    }

    const isOpen = () => {
        setCollapseOpen(!isCollapseOpen);
    }

    const isFileOpen = () => {
        setFileCollapseOpen(!isFileCollapseOpen);
    }

    return (
        !pageLoading ?
            <>
                <div id="fileAttachment" style={{paddingTop: '5px'}}>
                    <button className="collapsible" onClick={() => { isFileOpen() }}> <span >{!isFileCollapseOpen ? <FontAwesomeIcon className='action-icon' color="white" style={{ cursor: "pointer" }} icon={faPlus}></FontAwesomeIcon> : <FontAwesomeIcon className='action-icon' color="white" style={{ cursor: "pointer" }} icon={faMinus}></FontAwesomeIcon>} </span> File Attachments</button>
                    <Collapse isOpened={isFileCollapseOpen}>
                        <div className="box-body" >
                            <table className="table table-bordered table-striped">
                                <thead className="light-thead">
                                    <tr>
                                        <th style={{ width: '30px' }}>Action</th>
                                        <th>File Name</th>
                                        <th>File Type</th>
                                        <th>File Size</th>
                                    </tr>
                                </thead>
                                {attachmentData.length > 0 ?
                                    <tbody className="light-tbody">
                                        {attachmentData.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <FontAwesomeIcon id="download" className='action-icon' color="#007bff" style={{ cursor: "pointer" }} icon={faDownload} onClick={() => { downloadFile(item.id) }}></FontAwesomeIcon>
                                                        

                                                        {formMode === 'edit' ?
                                                            <FontAwesomeIcon id="delete"  className='action-icon' color="#007bff" style={{ cursor: "pointer" }} icon={faTrash} onClick={() => { deleteFile(item.id) }}></FontAwesomeIcon>


                                                            : null}
                                                        <Tooltip anchorSelect="#download" content="Download" place="bottom-end"></Tooltip>
                                                        <Tooltip anchorSelect="#delete" content="Delete" place="bottom-end"></Tooltip>
                                                    </td>
                                                    <td>{item.fileName}</td>
                                                    <td>{item.fileType}</td>
                                                    <td>{item.fileSize}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody> : "No record Found"}
                            </table>
                        </div>
                    </Collapse>
                </div>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                >
                    {successMessage ? (
                        <Alert severity="success">
                            <span style={{ fontSize: "18px" }}>{successMessage}</span>
                        </Alert>
                    ) : null}
                </Snackbar>
            </> : (
                <Box margin="auto" /* width='fit-content'  className="wrapper"*/>
                    <div className="main">
                        <div className="loader">
                            <div className="ball-clip-rotate-multiple">
                                <div></div>
                                <div></div>
                            </div>
                            <br />
                            <br />
                            <div className="loaderText">Loading...</div>
                        </div>
                    </div>
                </Box>
            )
    );
}

export default Attachments