//main file containing the authentication logic using okta. 
//Secure route is provided to route only authenticated requests.

import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config'
import Auth from './Auth';


//Dev
//const oktaAuth = new OktaAuth(config.oidc);
//Prod
const oktaAuth = new OktaAuth(config.oidcProd);
const MainWithRouter = (props) => {
    const history = useHistory();

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Switch>
                <Route path='/login/callback' component={LoginCallback} />
                <SecureRoute path='/' component={Auth} />
            </Switch>
        </Security>
    );
};
export default MainWithRouter;