import {React, useContext} from 'react'
import {useEffect, useState} from "react";
import { useOktaAuth } from '@okta/okta-react';
import { faHome, faChartSimple, faMagnifyingGlass,faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filter from './Filter';
import Tasks from './Tasks';
import { Select, MenuItem, InputLabel, FormControl, Box, List } from '@material-ui/core';
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import { UserContext } from '../Auth';
import {Link}from 'react-router-dom';
import GlobalSearch from './GlobalSearch';
import { Collapse } from 'react-collapse';
import PageHeader from './PageHeader';




const Home = (props) => {
    const user = useContext(UserContext);
    const { userInfo, sessionToken } = user;
    const [isReady, setIsReady] = useState(false);
    const [filterData, setFilterData] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [originalGridData, setOriginalGridData] = useState(null);
    const [hideButton, setHideButton] = useState(false);
    const [isFilterCollapseOpen, setFilterCollapseOpen] = useState(false);

    useEffect(()=>{
        getGridData();
        getFilterData();
    },[])


    //Get Filter data to load the dropdowns
    const getFilterData = async() =>{
        let data= []
        let body ={
            userEmail: userInfo.email,
        }
        try {
            data =await axios.
                post(Endpoints.getFilterDetails,body)
                .then((response) => {
                    return response.data;
                });
        } catch (err) {
            console.error(err);
        }
        console.log('filterdata', data);
        setFilterData(data)
    }

    //Get grid data to load the table
    const getGridData = async() =>{
        const body ={
            userEmail: userInfo.email,
        }

        let data= []
        try {
            data = await axios.
                post(Endpoints.gettaskdetails,body)
                .then((response) => {
                    return response.data;
                });
        } catch (err) {
            console.error(err);
        }

        console.log('griddata', data);
        setGridData(data)
        setOriginalGridData(data)
    }

    //Set filter collapse
    const isFilterOpen = () => {
        setFilterCollapseOpen(!isFilterCollapseOpen);
    }


    return (

        <>
            <PageHeader/>

            <div>
                <GlobalSearch />
            </div>

            {filterData && gridData ?
                (<div className='wrapper'>
                    <div className="page-block">
                        <div id="filter">
                            <button className="collapsible" onClick={() => { isFilterOpen() }}> <span >{!isFilterCollapseOpen ? <FontAwesomeIcon className='action-icon' color="white" style={{ cursor: "pointer" }} icon={faPlus}></FontAwesomeIcon> : <FontAwesomeIcon className='action-icon' color="white" style={{ cursor: "pointer" }} icon={faMinus}></FontAwesomeIcon>} </span>Filters</button>
                            <Collapse isOpened={isFilterCollapseOpen}>
                                <Filter filterData={filterData} gridData={gridData} originalGridData={originalGridData} setGridData={setGridData}></Filter>
                            </Collapse>
                        </div>
                        <Tasks gridDataByUser={gridData} userInfo={userInfo} hideButton={hideButton} ></Tasks>
                    </div>
                </div>) : (
                    <Box margin="auto" /* width='fit-content'  className="wrapper"*/>
                        <div className="main">
                            <div className="loader">
                                <div className="ball-clip-rotate-multiple">
                                    <div></div>
                                    <div></div>
                                </div>
                                <br />
                                <br />
                                <div className="loaderText">Loading...</div>
                            </div>
                        </div>
                    </Box>
                )}
        </>

    )
}
export default Home