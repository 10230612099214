import { React, useContext, useEffect, useState, useRef } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Attachments from './Attachments';
import NewForm from './NewForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { UserContext } from "../Auth";
import { useHistory, useParams } from "react-router-dom";
import PageHeader from './PageHeader';
import { ConfirmProvider } from "material-ui-confirm";



const NewFormTabs = (props) => {
    const formRef = useRef();
    const userData = useContext(UserContext);
    const { userInfo, sessionToken } = userData;
    const [activeTab, setActiveTab] = useState("NewForm");
    const [formMode, setFormMode] = useState('add');
    const { Id } = useParams();

    useEffect(() => {
        if (Id) {
            window.location.pathname.includes('edit') ? setFormMode('edit') : setFormMode('view');
        }
        else {
            setFormMode('add');
        }
    }, [Id]);

    const handleTabChange = (event, activeTab) => {
        setActiveTab(activeTab);

        ContentComponent(activeTab);
    };

    const ContentComponent = (prop) => {
        return (
            <>
                {prop.active === 'NewForm' ? (
                    NewFormSection()
                ) : prop.active === 'Attachment' ? (
                    Attachment()
                ) : ""}
            </>
        );
    }

    const NewFormSection = () => {
        return (
            <>
                <NewForm mode={formMode} Id={Id} />
            </>
        )
    }

    const Attachment = () => {
        return (
            <><ConfirmProvider>
                <Attachments taskId={Id} formMode={formMode} uploadedBy={userInfo.email} /></ConfirmProvider>
            </>
        );
    }

    return (
        <>
            <PageHeader/>
            <div className='page-body' style={{ marginTop: '50px' }}>
                <div className="page-content">
                    <div className="box" style={{ overflow: 'auto' }}>
                        <Tabs
                            value={activeTab}
                            textColor="primary"
                            indicatorColor="primary"
                            onChange={handleTabChange}>
                            {formMode === 'add' ? <Tab value="NewForm" label="New Form" /> : formMode === 'edit' ? <Tab value="NewForm" label="Edit Form" /> : <Tab value="NewForm" label="View Form" />}
                            {formMode !== 'add' ? <Tab value="Attachment" label="Attachments" /> : null}
                        </Tabs>
                        <ContentComponent active={activeTab} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewFormTabs