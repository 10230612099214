import React, {createContext} from 'react'
import { useOktaAuth } from '@okta/okta-react';
import {useEffect, useState} from "react";
import Main from './Main'


export const UserContext = createContext();   
const Auth = (props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [sessionToken, setSessionToken] = useState('')


    useEffect(() => {
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
            
        }
        else if(authState.isAuthenticated)  {
            oktaAuth.getUser().then(info => {                      
            setUserInfo(info);              
            
            setSessionToken(authState.idToken.idToken);                        
            });
        }
    }, [authState, oktaAuth]); 
   
  return (
    authState.isAuthenticated ?
    (
        userInfo&& sessionToken&& 
        <UserContext.Provider value={{userInfo,sessionToken}}>
            <Main {...props} />
        </UserContext.Provider>
  )
  : (<>
    <div className="App">
        <header className="App-header">
            {oktaAuth.signInWithRedirect()}
        </header>
    </div>
</>))
}
export default Auth