import { React, useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../Auth";
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import {  useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import Link from '@mui/material/Link';





const GlobalSearch = (props) => {


    const userData = useContext(UserContext);
    const { userInfo } = userData;
    const [value, setValue] = useState("");
    const [exactMatchResults, setExactMatchResults] = useState([]);
    const [results, setResults] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const history = useHistory();


    function onChangeOfInput(e) {
        setValue(e.target.value);
        fetchData(e.target.value);
    }

    //fetch data from server.
    const fetchData = async (value) => {
        const body = {
            userEmail: userInfo.email,
        }

        let data = []
        try {
            setLoadingSearch(true);
            if (value === "" || value.length <= 3) { setExactMatchResults([]); setLoadingSearch(false); }
            else { 
            data = await axios.
                post(Endpoints.gettaskdetails, body)
                .then((response) => {
                    const results = response.data.filter((action) => {
                        return (
                            action.uniqueActionId.toLowerCase().includes(value.toLowerCase()) ||
                            action.ownerName.toLowerCase().includes(value.toLowerCase()) ||
                            action.actionDescription.toLowerCase().includes(value.toLowerCase()) ||
                            action.created_by.toLowerCase().includes(value.toLowerCase()) 
                     );
                    });
                    setLoadingSearch(false);
                    setResults(response.data);
                    setExactMatchResults(results);

                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    // On select redirect to selected task.
    function redirectTo(id) {
        history.push("/edit/" + id);
    }

    function searchResult(searchValue) {
        history.push({ pathname: "/GlobalSearchResult", state: exactMatchResults, search: searchValue });
    }


    return (
        <>
            <div className="page-body">
                <div className="page-content">
                    <div className='wrapper' style={{ display: 'flex' , justifyContent :'flex-end'}} >
                        <div className="input-wrapper" id="globalSearch">
                            <FontAwesomeIcon icon={faMagnifyingGlass} color="black"></FontAwesomeIcon>
                            <input type="text" name="GlobalSearch" placeholder="Search" value={value} onChange={onChangeOfInput} style={{ marginRight: '10px' }} 
                            />             

                            <div style={{ position: "relative", right: "20px", top: "2px", zIndex: "1000", color: 'black' }}>
                                {loadingSearch ? <FontAwesomeIcon color="black" icon={faSpinner} spinPulse /> : ""}
                            </div>
                            {/*{value.length >= 3 ? <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => searchResult()} className="searchClass"></FontAwesomeIcon> : <FontAwesomeIcon icon={faMagnifyingGlass} className="searchClass"></FontAwesomeIcon> }*/}
                        </div>
                        <span /> <span />
                        {value.length >= 3 ?

                            <div className="search-Button">
                                <button className="btn btn-primary" onClick={() => searchResult(value)}>Search</button>
                            </div>
                            : <div>
                                <button  className="btn btn-primary">Search</button>
                            </div>}
                        <Tooltip anchorSelect="#globalSearch" content="Search by Unique ID, Action Owner, Action Creator, Action Description" place="left" style={{}}></Tooltip>
                        <div className="results-list">
  
                            {exactMatchResults.slice(0, 3).map((result, id) => {
                                return <div className="search-result" key={id} onClick={(e) => redirectTo(result.taskId)}>

                                    <List>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={<>{result.uniqueActionId} <br /> </>}
                                                secondary={
                                                    <>
                                                        <b>Description</b> : {result.actionDescription} <br/>
                                                        <b>Customer</b> : {result.customers.customerName} <br />
                                                        <b>Site</b> : {result.sites.siteName}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        <Divider  component="li" />
                                      </List>
                                </div>
                            })}

                            {exactMatchResults.length >= 3 && value.length >= 3 && loadingSearch === false ?
                                <Link style={{ textAlign: "center", fontSize: "0.850rem" }}
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        searchResult(value);
                                    }}
                                >
                                    Show more
                                </Link>:""
                            }
                        </div>
                        {exactMatchResults.length <= 0 && value.length >= 3 && loadingSearch ===false?
                             <div className="results-list">
                                <div className="search-result"> <List><ListItem alignItems="flex-start"> No Record Found</ListItem></List></div>
                            </div>:""
                        }


                     
                    </div>
                </div>
            </div>
        </>
    )
}
export default GlobalSearch