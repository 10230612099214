//used to create new/edit/view record in VMT. 
import { React, useContext, useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import * as yup from "yup";
import { Alert } from '@mui/material';
import { Snackbar, Box } from "@material-ui/core";
import { UserContext } from "../Auth";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faHome,faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import { Tooltip } from 'react-tooltip'
import CustomSelect from './CustomSelect';

const NewForm = (props) => {
    const formRef = useRef();
    const userData = useContext(UserContext);
    const { userInfo, sessionToken } = userData;
    const [initBus, setInitBus] = useState(null);
    const [initSegments, setInitSegments] = useState(null);
    const [initSites, setInitSites] = useState(null);
    const [initCustomer, setInitCustomer] = useState(null);
    const [initCategory, setInitCategory] = useState(null);
    const [initStatus, setInitStatus] = useState(null);
    const [initPLLines, setInitPLLines] = useState(null);
    //const { Id } = useParams();
    const { Id, mode } = props;
    const [formMode, setFormMode] = useState(mode);
    // const { values, setFieldValue } = useFormikContext();
    let history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitMode, setSubmitMode] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [isCollapseOpen, setCollapseOpen] = useState(false);
    const [selectedfile, SetSelectedFile] = useState([]);
    const [customerItem, setCustomerItem] = useState([]);
    const [siteItem, setSiteItem] = useState([]);

    //used to close the message window popup
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    const [ulOpen, setULOpen] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [userSearchValue, setUserSearchValue] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [suggest, setSuggest] = useState([]);

    //used to fetch the users for owner from AD
    async function fetchAllUsers(userVal) {

        let axiosConfig = {
            crossDomain: true
        };

        return axios.get(Endpoints.getListofUsers+ userVal, axiosConfig);

    }

    //event triggering for user search
    const onSearch = (e) => {
        let searchVal = e.target.value;
        if (searchVal.length > 3)
            getUserSearchSuggestion(searchVal);

        setUserSearchValue(searchVal);

    }
    //method to get user search values
    const getUserSearchSuggestion = (searchVal) => {
        let suggestion = [];

        if (searchVal.length > 3) {
            setLoadingSearch(true);
            fetchAllUsers(searchVal)
                .then(data => {
                    suggestion = data.data.filter((item) => {
                        if (item.Name.toLowerCase().includes(searchVal.toLowerCase()) || item.Email.toLowerCase().includes(searchVal.toLowerCase())) {
                            setLoadingSearch(false);
                            setULOpen(true);
                            return item;
                        }
                    });
                    setSuggest(suggestion);
                });

        }

    }
    //start the search
    const triggerUserSearch = (e) => {
        if (e.target.value.length > 3) {
            getUserSearchSuggestion(e.target.value);
        }
        else {
            setULOpen(false);
            // setFieldValue(`${props.name}`, "");
            // setFieldValue(`${props.name}_email`, "");
            // initialValues.OwnerName = "";
            // initialValues.OwnerName_email = "";
            formRef.current.setFieldValue("OwnerName", "");
            formRef.current.setFieldValue("OwnerName_email", "");
        }
    }
    //assign the selected owner details to the form fields
    const setUserFieldValue = (e) => {
        // initialValues.OwnerName = e.Name;
        formRef.current.setFieldValue("OwnerName", e.Name);
        setUserSearchValue(e.Name);
        // initialValues.OwnerName_email = e.Email.toLowerCase();
        formRef.current.setFieldValue("OwnerName_email", e.Email.toLowerCase());
        setULOpen(false);
    }
    //display the user suggestions in div for owner selection
    const getUserSuggestion = () => {
        if (!ulOpen && suggest.length > 3 && userSearchValue !== "") {
            return //<p className="autonouser">No Users</p>
        }
        else if (ulOpen) {
            return (
                suggest.length > 0 ?
                    <ul className="autolistB">
                        {
                            suggest.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <li className="autolist-li" onClick={() => setUserFieldValue(item)}
                                            name={item.Name}
                                            value={item.Email}>{item.Name}</li>
                                        {index !== suggest.length - 1}
                                    </div>
                                );
                            })
                        }
                    </ul>
                    :

                    <ul className="autolistB">
                        <div>
                            <li className="autolist-li"
                            >No Results</li>
                        </div>
                    </ul>
            )
        }
    }
    //set the initial values for formik fields
    const [initialValues, setInitialValues] = useState({
        UniqueActionId:"",
        BuId: "",
        SegmentId: "",
        SiteId: "",
        CustomerId: "",
        ActionPlan: "",
        ActualActions: "",
        CategoryId: "",
        FOL: "",
        CMT: "",
        DueDate: "",
        StatusId: "",
        OwnerName: "",
        OwnerName_email: "",
        created_by_email: userInfo.email,
        created_by: userInfo.name,
        EffectedPLLine: "",
        InvestmentCapexReq: "",
        ActionDescription: "",
        Comments: "",
        StartDate: "",
        ActualCloseDate: "",
        Quarterly_COP_Impact: ""

    });
    //get the initial values for the dropdowns
    useEffect(() => {
        setPageLoading(true);
        getInitialData();
    }, []);
    //get the VMT details when view or editing the record as per the request URL
    useEffect(() => {
        if (Id) {
            window.location.pathname.includes('edit') ? setFormMode('edit') : setFormMode('view');
            // setFormMode('edit');
            getTaskDetails(Id);
            setUserSearchValue(initialValues.OwnerName);
        }
        else
        {
            setFormMode('add');
        }
    }, [Id]);
    //setting the formik fields with the VMT details
    const getTaskDetails = async (id) => {
        try {
            let resp = await axios.get(Endpoints.getTaskDetail + id).then((response) => {
                return response;
            });

            let actual = resp.data.actualActions.toString()
            let actions = actual.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            let action = resp.data.actionPlan.toString()
            let actionPlan = action.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            let quarterly = resp.data.quarterly_COP_Impact.toString()
            let quarterlyCOPImpact = quarterly.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            resp && resp.data ? setInitialValues({
                UniqueActionId:resp.data.uniqueActionId,
                BuId: resp.data.buId,
                SegmentId: resp.data.segmentId,
                SiteId: resp.data.siteId,
                CustomerId: resp.data.customerId,
                ActionPlan: actionPlan,
                ActualActions: actions,
                CategoryId: resp.data.categoryId,
                FOL: resp.data.fol,
                CMT: resp.data.cmt,
                DueDate: new Date(
                    resp.data.dueDate
                )
                    .toDatetimeLocal().slice(0, 10),
                StatusId: resp.data.statusId,
                OwnerName: resp.data.ownerName,
                OwnerName_email: resp.data.ownerEmailId,
                created_by_email: resp.data.created_by_email,
                created_by: resp.data.created_by,
                EffectedPLLine: resp.data.effectedPLLine,
                InvestmentCapexReq: resp.data.investmentCapexReq,
                ActionDescription: resp.data.actionDescription,
                Comments: resp.data.comments,
                StartDate: new Date(resp.data.startDate).toDatetimeLocal().slice(0, 10),
                ActualCloseDate: resp.data.actualCloseDate ? new Date(resp.data.actualCloseDate).toDatetimeLocal().slice(0, 10) : "",
                Quarterly_COP_Impact: quarterlyCOPImpact
            }) : console.log('Error in getting response');
            setUserSearchValue(resp.data.ownerName);
            // console.log(resp);toISOString().slice(0,10)
        } catch (err) {
            console.error(err);
        }
        setPageLoading(false);
    }
    //method converting the DB date to the format accepted by formik date field
    Date.prototype.toDatetimeLocal = function toDatetimeLocal() {
        var date = this,
            ten = function (i) {
                return (i < 10 ? "0" : "") + i;
            },
            YYYY = date.getFullYear(),
            MM = ten(date.getMonth() + 1),
            DD = ten(date.getDate()),
            HH = ten(date.getHours()),
            II = ten(date.getMinutes()),
            SS = ten(date.getSeconds());
        return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II + ":" + SS;
    };
    //method getting the initial data from DB to fill the drop downs during first page load
    const getInitialData = async () => {
        let resp = [];
        try {
            await axios.get(Endpoints.creationinit).then((response) => {
                resp = response.data;
            });
        } catch (err) {
            console.error(err);
        }
        // console.log('data', resp.bUs);
        setInitBus(resp.bUs);
        setInitSegments(resp.segments);
        setInitSites(resp.sites);
        setInitCustomer(resp.customers);
        setInitCategory(resp.categories);
        setInitStatus(resp.status);
        setInitPLLines(resp.plLines);
        if (formMode == 'add') {
            setPageLoading(false)
        }

        setCustomerItem(resp.customers.map(item => ({ ['value']: item.customerId, ['label']: item.customerName })));
        setSiteItem(resp.sites.map(item => ({ ['value']: item.siteId, ['label']: item.siteName })));


    }
    //validation logic for the forik fields
    const validateForm = yup.object().shape({
        BuId: yup.number().required("BU is required"),
        SegmentId: yup.number().required("Segment is required"),
        SiteId: yup.number().required("Site is required"),
        CustomerId: yup.number().required("Customer is required"),
        ActionPlan: yup.string().required("Action Plan is required"),
        Quarterly_COP_Impact: yup.string().required("Action Plan is required"),
        CategoryId: yup.number().required("Category is required"),
        StatusId: yup.number().required("Status is required"),
        OwnerName: yup.string().required("Owner is required"),
        DueDate: yup.string().required("Due Date is required"),
        EffectedPLLine: yup.string().required("Effected P&L Line is required"),
        StartDate: yup.string().required("Start Date is required"),
        ActionDescription: yup.string().required("Action Description is required"),
        FOL: yup.string().required("FOL is required"),
        CMT: yup.string().required("CMT is required")
    });
    //method used to submit the data to DB
    const handleSubmit = async (values) => {
        let actionsResult = /^[0-9,]*$/.test(values.ActualActions);
        let actionsPlan = /^[0-9,]*$/.test(values.ActionPlan)
        let quarterly = /^[0-9,]*$/.test(values.Quarterly_COP_Impact)

        if (actionsResult && actionsPlan && quarterly) {
            let actual = values.ActualActions.toString();
            let actions = parseInt(actual.replace(",", ""))
            let action = values.ActionPlan.toString();
            let actionPlan = parseInt(action.replace(",", ""))
            let quarterly = values.Quarterly_COP_Impact.toString();
            let quarterlyCOPImpact = parseInt(quarterly.replace(",", ""))

            let body = {
                "UniqueActionId":values.uniqueActionId,
                "BuId": values.BuId,
                "SegmentId": values.SegmentId,
                "SiteId": values.SiteId,
                "CustomerId": values.CustomerId,
                "ActionPlan": Number.isInteger(actionPlan) ? parseFloat(actionPlan) : 0.00,
                "ActualActions": Number.isInteger(actions) ? parseFloat(actions) : 0.00,
                "CategoryId": values.CategoryId,
                "FOL": values.FOL,
                "CMT": values.CMT,
                "DueDate": values.DueDate,
                "StatusId": values.StatusId,
                "OwnerName": values.OwnerName,
                "OwnerEmailId": values.OwnerName_email,
                "created_by_email": values.created_by_email,
                "created_by": values.created_by,
                "ActionDescription": values.ActionDescription,
                "Comments": values.Comments,
                "InvestmentCapexReq": values.InvestmentCapexReq,
                "EffectedPLLine": values.EffectedPLLine,
                "StartDate": values.StartDate,
                "Quarterly_COP_Impact": Number.isInteger(quarterlyCOPImpact) ? parseFloat(quarterlyCOPImpact) : 0.00
            };
            if(values.ActualCloseDate)
                body = { ...body, ActualCloseDate: values.ActualCloseDate};

            if (selectedfile.length > 0) {
                body = { ...body, "Fileupload": selectedfile }
            }

            setLoading(true);
            if (formMode == 'add')
                await axios.post(Endpoints.createtask, body).then((response) => {
                    console.log("inserted" + response);
                    setSuccessMessage(
                        `Task has been successfully created`
                    );
                    setOpenSnack(true);
                    setTimeout(() => {
                        history.push(`/`);
                    }, 4000);
                }).catch((error) => {
                    console.log("error:" + error);
                    setErrorMessage(error);
                    setOpenSnack(true);
                });
            if (formMode == 'edit') {
                const newbody = { ...body, TaskId: Id, modified_by_email: userInfo.email, modified_by: userInfo.name };
                await axios.put(Endpoints.updateTaskDetail, newbody).then((response) => {
                    console.log("updated" + response);
                    setSuccessMessage(
                        `Task has been updated successfully`
                    );
                    setOpenSnack(true);
                    setTimeout(() => {
                        history.push(`/`);
                    }, 4000);
                }).catch((error) => {
                    console.log("error:" + error);
                    setErrorMessage(error);
                    setOpenSnack(true);
                });
            }
        } else {
            alert("Please enter only numbers and comma for $Planned Action Amount, $Closed Action Amount and $Quarterly COP Impact");
        }
        // resetForm();
    }

    const isOpen = () => {
        setCollapseOpen(!isCollapseOpen);
    }

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        // --For Multiple File Input
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size > 5 * 1024 * 1024) {
                alert("Please choose file below 5 MB.")
            } else {
                let reader = new FileReader();
                let file = e.target.files[i];
                reader.onloadend = () => {
                    SetSelectedFile((prevalue) => {
                        return [...prevalue,
                        {
                            fileUploadId: selectedfile.length + 1 + i,
                            attachmentTaskId: Id,
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            filesize: filesizes(e.target.files[i].size),
                            filecontent: reader.result.toString(),
                            uploadedBy: userEmail
                        }
                        ]
                    });
                }
                reader.readAsDataURL(file);

            }
        }
    }

    const removeSelectedFile = (id) => {
        const result = selectedfile.filter((data) => data.fileUploadId != id);

        SetSelectedFile(result);
    }

    return (
        !pageLoading ?
            <>
                <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validateForm}
                    innerRef={formRef}
                    onSubmit={async (values, { resetForm }) => {
                        handleSubmit(values);
                        // resetForm();
                        // history.push(`/`);
                        // setFieldValue("SiteId", "");
                        // setFieldValue("CustomerId", "");
                        // setFieldValue("OwnerName", "");
                        // setFieldValue("OwnerName_email", "");
                    }}
                // onReset={(values, {resetForm}) => {
                //     initialValues.OwnerName = "";
                //     setUserSearchValue("");
                // }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                    }) => {
                        return (
                            <>
                                <Form autoComplete='off'>
                                    <div className="container" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                        <div className="filter-title">VMT Task Creation</div>
                                        <div className="row  m-top">
                                            <div className='col-md-6'>

                                                <label className='control-label'>Action ID</label>
                                                <Field type="text" className='form-control input-sm take'
                                                    name='UniqueActionId' disabled={true}></Field>

                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>

                                                <label className='control-label'>Action Creator</label>
                                                <Field type="text" className='form-control input-sm take'
                                                    name='created_by_email' disabled={true}></Field>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className={`form-group ${errors['SegmentId'] && touched['SegmentId'] ? "has-error" : ""}`}>
                                                    <label className='control-label'>Segment*</label>
                                                    {initSegments && Array.isArray(initSegments) ?
                                                        <Field as="select" className="form-control input-sm take"
                                                            name="SegmentId" disabled={formMode == 'view' ? true : false} >
                                                            <option value="" hidden></option>
                                                            {initSegments.map((data) => {
                                                                return <option value={data.segmentId} key={data.segmentId}>{data.segmentName}</option>
                                                            })}
                                                        </Field> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.BuId && touched['BuId']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>BU*</label>
                                                    {initBus && Array.isArray(initBus) ?
                                                        <Field as="select" className="form-control input-sm take"
                                                            name="BuId" disabled={formMode == 'view' ? true : false} >
                                                            <option value="" hidden></option>
                                                            {initBus.filter((data) => data.segmentId == values.SegmentId).map((data) => {

                                                                return <option value={data.buId} key={data.buId}>{data.buName}</option>
                                                            })}
                                                        </Field> : ""}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.SiteId && touched['SiteId']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label' >Site*</label>
                                                    {initSites && Array.isArray(initSites) ?
                                                        // <Autosuggest initState={initSites} mode='site' name='SiteId'></Autosuggest>
                                                        <Field
                                                            id="site" 
                                                            className="form-control input-sm take"
                                                            name="SiteId"
                                                            component={CustomSelect}
                                                            options={siteItem}
                                                            placeholder="Enter Flex Sites"
                                                            isMulti={false}
                                                            isDisabled={formMode === 'view' ? true : false}
                                                        />
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.CustomerId && touched['CustomerId']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Customer*</label>
                                                    {initCustomer && Array.isArray(initCustomer) ?
                                                        // <Autosuggest initState={initCustomer} mode='customer' name='CustomerId'></Autosuggest>
                                                        <Field
                                                            id="customer"
                                                            className="form-control input-sm take"
                                                            name="CustomerId"
                                                            component={CustomSelect}
                                                            options={customerItem}
                                                            placeholder="Enter Flex Customer"
                                                            isMulti={false}
                                                            isDisabled={formMode === 'view' ? true : false}
                                                        /> : ""}
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.CategoryId && touched['CategoryId']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Action Category*</label>
                                                    {initCategory && Array.isArray(initCategory) ?
                                                        <Field as="select" className="form-control input-sm take"
                                                            name="CategoryId" disabled={formMode == 'view' ? true : false} >
                                                            <option value="" hidden></option>
                                                            {initCategory.map((data) => {

                                                                return <option value={data.categoryId} key={data.categoryId}>{data.categoryName}</option>
                                                            })}
                                                        </Field> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.EffectedPLLine && touched['EffectedPLLine']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>P&L Line*</label>
                                                    {initPLLines && Array.isArray(initPLLines) ?
                                                        <Field as="select" className="form-control input-sm take"
                                                            name="EffectedPLLine" disabled={formMode == 'view' ? true : false} >
                                                            <option value="" hidden></option>
                                                            {initPLLines.map((data) => {
                                                                return <option value={data.plLineId} key={data.plLineId}>{data.plName}</option>
                                                            })}
                                                        </Field> : ""}
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.ActionDescription && touched['ActionDescription']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Action Description*</label>
                                                    <Field type="textarea" className='form-control input-sm take'
                                                        name='ActionDescription' disabled={formMode == 'view' ? true : false}></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.ActionPlan && touched['ActionPlan']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Planned Action Amount ($)*</label>
                                                    <Field name='ActionPlan' type='text' className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.StartDate && touched['StartDate']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Start Date*</label>
                                                    <Field name='StartDate' type='date'
                                                        className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.Quarterly_COP_Impact && touched['Quarterly_COP_Impact']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Quarterly COP Impact ($)*</label>
                                                    <Field name='Quarterly_COP_Impact' type='text' className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.FOL && touched['FOL']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Included in FOL*</label>
                                                    <Field as="select" className="form-control input-sm take"
                                                        name="FOL" disabled={formMode == 'view' ? true : false} >
                                                        <option value="" hidden></option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.CMT && touched['CMT']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Claim Entered in CMT*</label>
                                                    <Field as="select" className="form-control input-sm take"
                                                        name="CMT" disabled={formMode == 'view' ? true : false} >
                                                        <option value="" hidden></option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                        <option value="NA">NA</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.InvestmentCapexReq
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Investment Capex Required</label>
                                                    <Field as="select" className="form-control input-sm take"
                                                        name="InvestmentCapexReq" disabled={formMode == 'view' ? true : false} >
                                                        <option value="" hidden></option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </Field>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.DueDate && touched['DueDate']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Due Date*</label>
                                                    <Field name='DueDate' type='date' min='0'
                                                        className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div>
                                                    <label className='control-label'>Closed Action Amount ($)</label>
                                                    <Field name='ActualActions' type='text' className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.ActualCloseDate && touched['ActualCloseDate']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Actual Close Date</label>
                                                    <Field name='ActualCloseDate' type='date' min='0'
                                                        className="form-control input-sm take" disabled={formMode == 'view' ? true : false} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.StatusId && touched['StatusId']
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Status*</label>
                                                    {initStatus && Array.isArray(initStatus) ?
                                                        <Field as="select" className="form-control input-sm take"
                                                            name="StatusId" disabled={formMode == 'view' ? true : false} >
                                                            <option value="" hidden></option>
                                                            {initStatus.map((data) => {
                                                                return <option value={data.statusId} key={data.statusId}>{data.statusDescription}</option>
                                                            })}
                                                        </Field> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.OwnerName && touched['OwnerName'] && values['OwnerName'] == ''
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Action Owner*</label>

                                                    <>
                                                        <Field type="text" className="form-control input-sm take"
                                                            placeholder="Enter Flex User Name ( Min 4 Chars)"
                                                            onChange={onSearch}
                                                            value={userSearchValue.length > 0 ? userSearchValue : ''}
                                                            onKeyUp={triggerUserSearch}
                                                            attr-email={userEmail}
                                                            name="OwnerName" disabled={formMode == 'view' ? true : false}
                                                        />
                                                        <div style={{ position: "absolute", right: "5px", top: "2px", zIndex: "1000", color: 'black' }}>
                                                            {loadingSearch ? <FontAwesomeIcon color="black" icon={faSpinner} spinPulse /> : ""}
                                                        </div>
                                                        {getUserSuggestion()}
                                                        <Field type="text" name="OwnerName_email" hidden />
                                                    </>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div
                                                    className={`form-group ${errors.Comments
                                                        ? "has-error"
                                                        : ""
                                                        }`}
                                                >
                                                    <label className='control-label'>Comments</label>
                                                    <Field type="textarea" as="textarea" className='form-control input-sm take'
                                                        name='Comments' disabled={formMode == 'view' ? true : false}></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                {submitMode == 'submit' && Object.keys(errors).length ? (
                                                    <div style={{ color: "red", margin: "10px" }}>
                                                        Form is missing information
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {formMode !== 'view' ?
                                            <>
                                                <div>
                                                    <div className="collapsible" onClick={() => { isOpen() }} id="fileUpload" style={{ cursor: "pointer" }}> <span >{!isCollapseOpen ? <FontAwesomeIcon className='action-icon' color="white" icon={faPlus}></FontAwesomeIcon> : <FontAwesomeIcon className='action-icon' color="white"  icon={faMinus}></FontAwesomeIcon>} </span> File Upload </div>
                                                    <Collapse isOpened={isCollapseOpen}>
                                                        <div className="attachment-box-body" >
                                                            <div className="file-upload-box">
                                                                <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
                                                                <span>Drag and drop or <span>Choose files</span> (Max file size: 5 MB)</span>
                                                            </div>

                                                            <br />
                                                            {selectedfile.length > 0 ?
                                                                <table className="table table-bordered table-striped">
                                                                    <thead className="light-thead">
                                                                        <tr>
                                                                            <th style={{ width: '50px' }}>Action</th>
                                                                            <th>File Name</th>
                                                                            <th>File Type</th>
                                                                            <th>File Size</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="light-tbody">
                                                                        {selectedfile.map((itemData) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <button className="btn btn-danger ml-3" onClick={() => { removeSelectedFile(itemData.fileUploadId) }}>
                                                                                            Remove
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{itemData.filename}</td>
                                                                                    <td>{itemData.filetype}</td>
                                                                                    <td>{itemData.filesize}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                : null}
                                                            <br />
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                <Tooltip anchorSelect="#fileUpload" content="Click to upload files" place="bottom-start"></Tooltip>
                                                <br />
                                            </> : null}

                                        <div className='row justify-content-center'>
                                            <div className='d-flex justify-content-between' text-align=''>
                                                <div>
                                                    {formMode == 'edit' ?
                                                        <button
                                                            type="submit"
                                                            name="wf"
                                                            className="btn btn-success"
                                                            onClick={() => setSubmitMode('submit')}
                                                            disabled={isSubmitting}
                                                        > {!loading ?
                                                            "Update" : <FontAwesomeIcon icon={faSpinner} />}
                                                        </button> :
                                                        formMode == 'add' ?
                                                            <button
                                                                type="submit"
                                                                name="wf"
                                                                className="btn btn-success"
                                                                onClick={() => setSubmitMode('submit')}
                                                                disabled={isSubmitting}
                                                            > {!loading ?
                                                                "Submit" : <FontAwesomeIcon icon={faSpinner} />}
                                                            </button> : ""}
                                                </div>
                                                <div>
                                                    <Link
                                                        type="reset"
                                                        name="rest"
                                                        to={`/`}
                                                        className="btn btn-danger ml-3"
                                                    >
                                                        Cancel
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <Snackbar
                                    open={openSnack}
                                    autoHideDuration={8000}
                                    onClose={handleCloseSnack}
                                >
                                    {successMessage ? (
                                        <Alert severity="success">
                                            <span style={{ fontSize: "18px" }}>{successMessage}</span>
                                        </Alert>
                                    ) : (
                                        <Alert severity="error">

                                            <span style={{ fontSize: "18px" }}>There was an error with your submission please try again</span>
                                            <br />
                                            {errorMessage}
                                        </Alert>

                                    )}
                                </Snackbar>

                            </>
                        )
                    }}
                </Formik>
            </> : (
                <Box margin="auto" /* width='fit-content'  className="wrapper"*/>
                    <div className="main">
                        <div className="loader">
                            <div className="ball-clip-rotate-multiple">
                                <div></div>
                                <div></div>
                            </div>
                            <br />
                            <br />
                            <div className="loaderText">Loading...</div>
                        </div>
                    </div>
                </Box>
            )



    )

}

export default NewForm