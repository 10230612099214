
import React from 'react';
// import "./components/css/bootstrap.css";
// import "./components/css/CSER-MainStyles.css";
// import "./components/css/customStyles.css";
import { BrowserRouter as Router } from 'react-router-dom';
import MainWithRouter from './MainWithRouter';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';


function App() {

  return (
    <>
    <Router>
      <MainWithRouter />
    </Router>
  </>
  );
}

export default App;
