import config from '.././appConfig.json'
//Stag
//const azureBaseUrl = 'https://flextaskmanagerws-staging.azurewebsites.net'

//Dev
//const azureBaseUrl = 'https://flwappvmtd00001api.azurewebsites.net'

//QA
//const azureBaseUrl = 'https://flwappvmtqa00001api.azurewebsites.net'

//Prod
//const azureBaseUrl = 'https://flextaskmanagerws.azurewebsites.net'

//const azureBaseUrl = 'https://localhost:7233'
const azureBaseUrl = config.azureBaseUrl;

export const Endpoints = {
    creationinit: `${azureBaseUrl}/api/ManageTasks/getInitDetails`,
    getListofUsers: `${azureBaseUrl}/api/ManageTasks/getListOfMembers?userquery=`,
    createtask: `${azureBaseUrl}/api/ManageTasks/createTask`,
    gettaskdetails: `${azureBaseUrl}/api/ManageTasks/getTaskDetails`,
    getFilterDetails: `${azureBaseUrl}/api/ManageTasks/getFilterDetails`,
    getTaskDetail: `${azureBaseUrl}/api/ManageTasks/getTaskDetail?id=`,
    updateTaskDetail: `${azureBaseUrl}/api/ManageTasks/updateTask`,
    getTaskGridColumns: `${azureBaseUrl}/api/ManageTasks/getTaskGridColumns`,
    getGridColumnsByUser: `${azureBaseUrl}/api/ManageTasks/getGridColumnsByUser`,
    saveUpdateGridColumnByUser: `${azureBaseUrl}/api/ManageTasks/saveUpdateGridColumnByUser`,
    uploadFiles: `${azureBaseUrl}/api/ManageTasks/uploadFiles`,
    getAttachmentDetails: `${azureBaseUrl}/api/ManageTasks/getAttachmentDetails?taskId=`,
    deleteAttachment: `${azureBaseUrl}/api/ManageTasks/deleteAttachment?id=`,
    downloadAttachment: `${azureBaseUrl}/api/ManageTasks/downloadAttachment?id=`

}