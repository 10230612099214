import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import config from '../appConfig.json'

const PageHeader = (props) => {

    const appVersion = config.version;
    const dashbordUrl = config.dashbordUrl;

    return (
        <>
            < div className='header' >
                <div className="header-block d-flex flex-row justify-content-between">
                    <div className="header-left">
                        <div className="app-brand"> VMT Action Tracker <div className="app-version">{appVersion}</div></div>
                    </div>
                    <div className="header-right d-flex flex-row align-items-stretch justify-content-between">
                        <div className="header-right-actions d-flex flex-row align-items-stretch">
                            <div className="header-right-menu d-flex flex-row align-items-stretch">
                                <div className="header-right-menu-item d-flex align-items-stretch">
                                    <Link to={`/`} className="btn btn-header-menu-item px-3">
                                        <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                                        <div className="header-menu">Home</div>
                                    </Link>
                                    <Link target={"_blank"} to={{ pathname: dashbordUrl }} className="btn btn-header-menu-item px-3">
                                        <FontAwesomeIcon icon={faChartSimple} />
                                        <div className="header-menu">Dashboard</div>

                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


export default PageHeader