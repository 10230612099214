import {React, useContext} from 'react'
import {useEffect, useState, useRef} from "react";
import { useOktaAuth } from '@okta/okta-react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '@fluentui/react';
import { Select, MenuItem, InputLabel, Box, FormControl } from '@material-ui/core';
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import { Formik, Form, Field } from 'formik';



 
const Filter = (props) => {
    const { userInfo, sessionToken, filterData, gridData, setGridData, originalGridData} = props;
    const [isReady, setIsReady] = useState(false);
    const { oktaAuth } = useOktaAuth();
    const [loading, setLoading]=useState(false)
    const formRef =useRef();
    const [initialValues, setInitialValues] = useState({
        segmentId: "",
        categoryId: "",
        buId: "",
        siteId: "",
        statusId: "",
        customerId: "",
        ownerName: ""        
    });
    
//Filter the grid based on selected filters
      const handleSubmit = () => {
           console.log('formRef', formRef)
           console.log('griddata', gridData);

           const keys = Object.entries(formRef.current.values).filter((item)=>{
                        return item[1] != ''
           })

           if(keys.length > 0) {
           let temporaryGrid = originalGridData;

           const filterData = keys.map((key)=>{
                    let result = [];
                    temporaryGrid.filter((grid)=>{
                          if(grid[key[0]] == key[1]) {
                            result.push(grid)                            
                          }                                                    
                    })
                    temporaryGrid = result;
                    return temporaryGrid
           })
                     

            setGridData(filterData[filterData.length-1]) }
            else {
                alert('Please select atleast one filter to proceed')
            }
      }

  return (

   filterData ? (<>            
            <div className='page-body'>    
              <div className="page-content" style={{ marginBottom: '0px'  }}>
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={async (values)=>{
                            handleSubmit(values)
                            }}>
                        {
                            formik =>
                           (
                            <Form>
                                
                                    {/*<div className="tab-content light-tab-content" style={{ padding: '5px' }}>*/}
                                {/*<div className="filter-title">Filters</div>*/}
                                
                                    <div className="form-accordion tab-content light-tab-content" style={{ marginBottom: '0px', padding:'5px'}}>
                                                <div className="form-accordion-body">                         
                                                            <div className='row'>
                                                            <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="segments">Segments</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="segmentId"
                                                                                    id="segments"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.segments.map((segment)=>{
                                                                                        return <MenuItem value={segment.segmentId}>{segment.segmentName}</MenuItem>
                                                                                    })}

                                                                                      
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                

                                                                </div>
                                                                <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="categories">Action Category</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="categoryId"
                                                                                    id="categories"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.categories.map((category)=>{
                                                                                        return <MenuItem value={category.categoryId}>{category.categoryName}</MenuItem>
                                                                                    })} 
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                

                                                                </div>
                                                                <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="business_unit">Business unit</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="buId"
                                                                                    id="business_unit"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.bUs.map((bU)=>{
                                                                                        return <MenuItem value={bU.buId}>{bU.buName}</MenuItem>
                                                                                    })}
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                

                                                                </div>
                                                                <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="status">Status</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="statusId"
                                                                                    id="status"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.status.map((data)=>{
                                                                                        return <MenuItem value={data.statusId}>{data.statusDescription}</MenuItem>
                                                                                    })} 
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                
                                                                </div>
                                                                <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="site">Site</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="siteId"
                                                                                    id="site"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.sites.map((site)=>{
                                                                                        return <MenuItem value={site.siteId}>{site.siteName}</MenuItem>
                                                                                    })}  
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                

                                                                </div>  
                                                                <div className="col-2">
                                                                <div className="form-group light-form-group">
                                                                    
                                                                                <label htmlFor="customer_name">Customer Name</label>
                                                                                <Field
                                                                                    as={Select}
                                                                                    name="customerId"
                                                                                    id="customer_name"
                                                                                    displayEmpty
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    className="form-control form-control-sm light-form-control"
                                                                                    
                                                                                >
                                                                                    <MenuItem value="" hidden>Select an Option</MenuItem>
                                                                                    {filterData.customers.map((customer)=>{
                                                                                        return <MenuItem value={customer.customerId}>{customer.customerName}</MenuItem>
                                                                                    })} 
                                                                                </Field>
                                                                                 
                                                                                
                                                                                </div>
                                                                                

                                                                </div>
                                                            </div >
                                                            <div className='row justify-content-end'>
                                                                <div className='d-flex justify-content-between'> 
                                                                    <div> 
                                                                        <button className='btn btn-success' type='submit'>Apply Filter</button>
                                                                    </div> 
                                                                    <div>
                                                                        <button className='btn btn-danger ml-3' id='resetButton' style={{marginRight:'16px'}} onClick={()=>setGridData(originalGridData)} type='reset'>Clear Filter</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                    </div >                                        
                                                        
                                            </div >
                                       {/* </div >*/}
                                            
                                            </Form>
                                            
                                            
                        )}
                    </Formik>
                </div>
            </div>
   </>) : <></> 

  )
}
export default Filter