import {React, createContext, useContext} from 'react'
import { useOktaAuth } from '@okta/okta-react';
import {useEffect, useState} from "react";
import { UserContext } from './Auth';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import  Home  from './Components/Home'
import './Css/bootstrap.css'
import './Css/main.css'
import './Css/loaders.min.css'
import NewForm from './Components/NewForm';
import GlobalSearchResult from './Components/GlobalSearchResult'; 
import NewFormTabs from './Components/NewFormTabs';

 
const Main = (props) => {
    const user = useContext(UserContext);
    const { userInfo, sessionToken } = user;
    const [isReady, setIsReady] = useState(false);

    function getUserInfo() {
        setIsReady(true)
    }

    useEffect(()=>{
       getUserInfo()
    },[userInfo])
    
  return (
    
    isReady && userInfo && (
        <Switch>
            <Route
             path='/NewForm'
                  render={(routeprops) =>(
                      <NewFormTabs {...props}></NewFormTabs>
                )} >

            </Route>

            <Route
             path='/edit/:Id'
                render={(routeprops) =>(
                    <NewFormTabs {...props}></NewFormTabs>
                )} >

            </Route>

            <Route
             path='/view/:Id'
                render={(routeprops) =>(
                    <NewFormTabs {...props}></NewFormTabs>
                )} >

              </Route>

              <Route
                  path='/GlobalSearchResult'
                  render={(routeprops) => (
                      <GlobalSearchResult {...props}></GlobalSearchResult>
                  )} >

              </Route>

            <Route path='/'
                render={(routeprops) =>(
                    <Home {...props}></Home>
                )} > 
            </Route>
        </Switch>

    )
  )
}
export default Main