import { useEffect, useContext, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../Auth';
import Tasks from './Tasks';
import { faHome, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from "./PageHeader";

const GlobalSearchResult = (props) => {

    const userData = useContext(UserContext);
    const { userInfo } = userData;
    const state = useLocation();
    const [hideButton, setHideButton] = useState(true);



    useEffect(() => {
        showData();
    });

    function showData() {
        console.log(state.search);
    }


    return (
        <>
            <PageHeader/>
            <div className='wrapper'>
                <div className="page-block">
                    <div style={{ marginLeft: "20px", fontSize: "15px", marginTop:'10px' }}><p>Search results for "{state.search.substring(1)}"</p></div>
                </div>
            </div>

            <div className='wrapper'>
                <div className="page-block">
                    <Tasks gridDataByUser={state.state} userInfo={userInfo} hideButton={hideButton}></Tasks>
                </div>
            </div>

        </>
    )
}
export default GlobalSearchResult