export default {
    oidc: {
        clientId: '0oa1zj139vda2bNQP0h8',
        issuer: 'https://flexdev.oktapreview.com',
        redirectUri: `${window.location.origin}/login/callback`,
        //redirectUri: 'https://sustainabilitydata.azurewebsites.net/login/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        //disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },

    oidcProd: {
        clientId: '0oas3tyqj6abFuOyX2p7',
        issuer: 'https://flex.okta.com',
        redirectUri: `${window.location.origin}/login/callback`,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
    },

}