import {  Tooltip, IconButton } from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faXmark } from '@fortawesome/free-solid-svg-icons';
import BuildIcon from '@mui/icons-material/Build';
import { Snackbar, Box } from "@material-ui/core";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import '../Css/ColumnOptions.css';



export const ColumnOptions = ({ userInfo, gridLoadHandler }) => {

    const [open, setOpen] = useState(false);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridColumnsByUser, setGridColumnsByUser] = useState([]);
    const [newColumn, setNewColumn] = useState("");
    //save reference for dragItem and dragOverItem
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);

    useEffect(() => {
        getDefaultGridColumn();
        getGridColumnByUser();
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
    setNewColumn("");
    setOpen(false);
    };

    const handleSave= () => {
        setNewColumn("");
        saveUpdateGridColumnByUser();
    };

    const getDefaultGridColumn = async () => {
        try {
            await axios.get(Endpoints.getTaskGridColumns).then((response) => {
                setGridColumns((response.data).sort((a, b) => a.displayName > b.displayName ? 1 : -1));
            });
        } catch (err) {
            console.error(err);
        }
    }
    const getGridColumnByUser = async () => {
        let body = { userEmail: userInfo.email}
        try {
            await axios.post(Endpoints.getGridColumnsByUser, body).then((response) => {
                if (response.data.columnDetails !== "[]" && response.data.columnDetails !== undefined) {
                    setGridColumnsByUser(JSON.parse(response.data.columnDetails));
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    const saveUpdateGridColumnByUser = async () => {
        let data = []
        const ColumnDetails = gridColumnsByUser.map((column, index) => {
            return {               
                columnID: column.Id,
                name: column.name,
                displayName: column.displayName,
                defaultSequence:column.defaultSequence,
                sequence: index
            };
        });

        let body = {
            email: userInfo.email,
            columnDetails: JSON.stringify(ColumnDetails)
        }
        try {
            data = await axios.
                post(Endpoints.saveUpdateGridColumnByUser, body)
                .then((response) => {
                    setSuccessMessage(
                        `Column details has been saved successfully`
                    );
                    gridLoadHandler();
                    setOpenSnack(true);
                    setTimeout(() => {
                        setOpen(false);
                    }, 500);
                   
                });
        } catch (err) {
            console.error(err);
            setErrorMessage(err);
            setOpenSnack(true);
        }
        console.log('GridColumnByUser', data);

    }

    //handle name change
    const onOptionChangeHandler = (newcolumn) => {
        setNewColumn(JSON.parse(newcolumn));
    };

    //handle new item addition
    const handleAddItem = () => {
        var isAvailable = gridColumnsByUser.filter(Items => Items.name === newColumn.name);
        if (newColumn !== "" && isAvailable.length===0) {
            const _gridColumnsByUser = [...gridColumnsByUser];
            _gridColumnsByUser.push(newColumn)
            setGridColumnsByUser(_gridColumnsByUser);
        }
    };
    const handleDeleteItem = (DeletedItem) => {
        const _gridColumnsByUser = [...gridColumnsByUser];
        setGridColumnsByUser(_gridColumnsByUser.filter(Items => Items.name !== DeletedItem.name));
    }

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
      let _gridColumnsByUser = [...gridColumnsByUser];

    //remove and save the dragged item content
      const draggedItemContent = _gridColumnsByUser.splice(dragItem.current, 1)[0];

    //switch the position
      _gridColumnsByUser.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

      //update the actual array
      console.log(_gridColumnsByUser);
      setGridColumnsByUser(_gridColumnsByUser);
  };
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };


    return (
        <>
            <span />
            <Tooltip title="Column options">
                <Button variant="contained" style={{ justifyContent: 'flex-end' }} className="btn btn-primary" onClick={handleClickOpen} startIcon={<BuildIcon />}></Button>           
            </Tooltip>

         <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          sx: {
          width: "30%",
          height: "80%",
          },
          onSubmit: (event) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>Column options</DialogTitle>
        <DialogContentText style={{marginLeft:"25px" }}>
             Add or remove columns. To change the column order, drag and drop a field
        </DialogContentText>
        <DialogContent>



      <div className="input-group">
       
                <select style={{ width: "88%" }} onChange={(e) => onOptionChangeHandler(e.target.value)}>
                <option selected="false" defaultValue disabled>Please select column</option>
                {gridColumns.map((item, index) => {
                    return (
                        <option key={index} value={ JSON.stringify(item) }>
                            {item.displayName}
                        </option>
                    );
                })}
         </select>
        <button className="btn" onClick={handleAddItem}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="list-sort">
          {gridColumnsByUser.map((item, index) => (
          <div
            key={index}
            className="list-item"
            draggable
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}>{item.displayName}
            <button className="btn" onClick={() => handleDeleteItem(item)}><FontAwesomeIcon icon={faXmark} /></button>
          </div>
        ))}
    </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'top',horizontal: 'center' }}
                open={openSnack}
                autoHideDuration={1000}
                onClose={handleCloseSnack}
            >
                {successMessage ? (
                    <Alert severity="success">
                        <span style={{ fontSize: "18px" }}>{successMessage}</span>
                    </Alert>
                ) : (
                    <Alert severity="error">

                        <span style={{ fontSize: "18px" }}>There was an error with your submission please try again</span>
                        <br />
                        {errorMessage}
                    </Alert>

                )}
            </Snackbar>
     </>
    )
}