import { React, useContext, useRef } from 'react'
import axios from 'axios';
import { Endpoints } from '../Configs/Endpoints';
import {useEffect, useState} from "react";
import { useOktaAuth } from '@okta/okta-react';
import { faHome, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '@fluentui/react';
import { Select, MenuItem, InputLabel, FormControl, TablePagination, Button } from '@material-ui/core';
import {Link}  from 'react-router-dom';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { ExportToExcel } from './ExportToExcel';
import {ColumnOptions} from './ColumnOptions'


 
const Tasks = (props) => {
    const { userInfo, sessionToken, gridDataByUser, hideButton } = props;
    const [isReady, setIsReady] = useState(false);
    const [open, setOpen] = useState(false);
    const { oktaAuth } = useOktaAuth();
    const [activePage, setActivePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeTab, setActiveTab] = useState("All");
    const [gridColumns, setGridColumns] = useState([]);
    const [gridData, setGridData] = useState(gridDataByUser);
    const [gridColumnsByUser, setGridColumnsByUser] = useState([]);
    const [actionPlan, setActionPlan] = useState(0);
    const [actualActions, setActualActions] = useState(0);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setActivePage(0);
    };
    const [sort, setSort] = useState({ keyToSort: "uniqueActionId", direction: "desc" });
    const [hide, setHideButton] = useState(hideButton);

    const handleChangePage = (event, newPage) => {
        setActivePage(newPage);
    };

    const handleHeaderClick = (column) => {
        setSort({
            keyToSort: column.name,
            direction: column.name === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc' : 'desc' });
    }
    const getSortedGridData = (data) => {
        return(
            data.sort((a, b) => {
             return(
                a[sort.keyToSort]?.toString().localeCompare(b[sort.keyToSort]?.toString(), "en", { numeric: true, }) * (sort.direction === 'asc' ? 1 : -1)
                );          
            }));
  }
    useEffect(() => {
        getGridColumnByUser();
        activeTab === "Creator" ?
            setGridData(gridDataByUser.filter((x) => x.created_by_email === userInfo.email))
            : activeTab === "Owner" ?
                setGridData(gridDataByUser.filter((i) => i.ownerEmailId === userInfo.email.toLowerCase()))
                : setGridData(gridDataByUser);
    }, [gridDataByUser, activeTab]);

    useEffect(() => {
        setActualActions((gridData.reduce((a, v) => a = a + v.actualActions, 0).toString()).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
        setActionPlan((gridData.reduce((a, v) => a = a + v.actionPlan, 0.00).toString()).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

    }, [gridData]);

    const handleTabChange = (event, activeTab) => {
        setActiveTab(activeTab);
    };

    const getGridColumnByUser = async () => {
        let body = { userEmail: userInfo.email }
        try {
            await axios.post(Endpoints.getGridColumnsByUser, body).then((response) => {
                if (response.data.columnDetails !== "[]" && response.data.columnDetails !== undefined) {
               setGridColumnsByUser(JSON.parse(response.data.columnDetails));
             }
             else {
             axios.get(Endpoints.getTaskGridColumns).then((response) => {
                        setGridColumnsByUser(response.data);
                    });
}
            });
        } catch (err) {
            console.error(err);
        }
    }

  return (

   <>   
        <div className='page-body'>
            <div className="page-content">
               
                    <div className="box-header with-border d-flex justify-content-between">
                          {/*<div className="box-title">All Tasks</div>*/}

                          <Tabs
                              value={activeTab}
                              textColor="primary"
                              indicatorColor="primary"
                              onChange={handleTabChange}>
                              <Tab value="All" label="All" />
                              <Tab value="Creator" label="Action Created" />
                              <Tab value="Owner" label="Action Owned" />
                          </Tabs>
                          <div className="box-header-action">                        
                            <div className="input-group input-group-sm">
                                  <span className='action-button'>
                               
                                      {!hide ? < Link to={`/NewForm`} title="New Request" className="btn btn-primary">New Activity</Link> : null}
                                      {hide ? < Link type="reset" name="rest" to={`/`} className="btn btn-danger ml-3">Back</Link> : null}
                                      <ColumnOptions userInfo={userInfo} gridLoadHandler={getGridColumnByUser } />
                                      <ExportToExcel user={userInfo} data={getSortedGridData(gridData)} columns={gridColumnsByUser } />

                            </span>
                        	</div>
							</div>
                  </div>
                  <div className="box" style={{ overflow: 'auto' }}>
                    {gridData.length > 0 ?
                    <> 
                              <div className="box-body" >
                                  <label className="MuiTypography-body2" style={{ fontWeight: 'bold' }} htmlFor="actionPlan">Total Planned Action Amount($) : {actionPlan} &emsp;&emsp; Total Closed Action Amount($) : {actualActions}</label><span />
                                  <table className="table table-bordered table-striped sortable">
                                      <thead className="light-thead">
                                <tr>
                                              <th key="-1" >Action</th>
                                              {
                                                     gridColumnsByUser && gridColumnsByUser.map((column, index) => {
                                                      const cl = sort.keyToSort === column.name && sort.direction === "asc"? "up"
                                                          : sort.keyToSort === column.name && sort.direction === "desc" ? "down": "default";
                                                      return (
                                                          <th className={cl} style={{ "min-width": '90px' }}  key={index} onClick={() => handleHeaderClick(column)}>{column.displayName}</th>)

                                                  })}
                                </tr>
                            </thead>
                            <tbody className="light-tbody">
                                          {gridData && gridData.length && getSortedGridData(gridData).slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage).map((itemData,index)=>{
                                    return <tr key={index}>
                                        <td key="-1"> 
                                                    <Link className='action-icon' to={`/view/${itemData.taskId}`} title="View">
                                                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                                    </Link>   
                                                    <Link  className='action-icon' to={`/edit/${itemData.taskId}`} title="Edit">
                                                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                    </Link> 
                                                </td>
                                        {
                                            gridColumnsByUser.map((header, index) => {
                                                return (<td key={index}>{itemData[header.name] }</td>)})
                                        }
                                        </tr>
                                })} 
                            </tbody>
                        </table>
                              </div> </> : <div className="records">No Records Found</div>}
                  </div>
                      <div className="box-footer-right" >
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={gridData.length}
                            page={activePage}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />                         
                    </div>
                
            </div>
        </div>
   </>    

  )
}
export default Tasks