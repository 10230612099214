
import React from "react";
import Select from "react-select";



export const CustomSelect = ({ placeholder = 'Select', field, form, options, isMulti = false, isDisabled = false}) => {
	function onChange(option) {
		form.setFieldValue(
			field.name,
			option ? (option).map((item) => item.value) : [],
		);
	}


	const getValue = () => {
		if (options) {
			return isMulti
				? options.filter((option) => field.value.indexOf(option.value) >= 0)
				: options.find((option) => option.value === field.value);
		} else {
			return isMulti ? [] : ('');
		}
	};



	if (!isMulti) {
		return (
			<Select 
				options={options}
				name={field.name}
				value={options ? options.find(option => option.value === field.value) : ''}
				onChange={(option) => form.setFieldValue(field.name, option.value)}
				onBlur={field.onBlur}
				placeholder={placeholder}
				isDisabled={isDisabled}
			/>
		)
	} else {
		return (
			<Select 
				className="react-select-container"
				classNamePrefix="react-select"
				name={field.name}
				value={getValue()}
				onChange={onChange}
				options={options}
				isMulti={true}
				placeholder={placeholder}
				isDisabled={isDisabled}
			/>
		)
	}
}


export default CustomSelect;