import { Button, Tooltip, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const ExcelJS = require("exceljs");
export const ExportToExcel = ({ user, data, columns }) => {

    const exportExcelFile = () => {

        const workbook = new ExcelJS.Workbook();

        const sheetNames = ["All", "Creator", "Owner"];

        sheetNames.forEach(sheetName => {
            let worksheet = workbook.addWorksheet(sheetName);
            worksheet.state = 'visible';

            worksheet.columns = columns?.map((column, index) => {
                return {
                    header: column.displayName,
                    key: column.name,
                    width: 15,
                    style: {
                        alignment: { wrapText: true, vertical: 'top', horizontal: 'left' },
                        font: {
                            size: 9,
                            name: 'Calibri'
                        }

                    }
                };
            });
            worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getRow(1).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFD1" },
            };

            worksheet.getRow(1).font = {
                name: "Cambria",
                family: 4,
                size: 10,
                bold: true,
                underline: true,

            };


        });



        const promise = Promise.all(
            data?.map(async (row, index) => {
                workbook.getWorksheet("All").addRow(row);
                if (row.created_by_email === user.email)
                    workbook.getWorksheet("Creator").addRow(row);
                if (row.ownerEmailId === user.email.toLowerCase())
                    workbook.getWorksheet("Owner").addRow(row);
            })
        );

        promise.then(() => {
            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = "VMTActionsDetails.xlsx";
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        });
    }

    return (
        <>
            <span />
            <Tooltip title="Export To Excel">
                <Button variant="contained" style={{ justifyContent: 'flex-end' }} className="btn btn-primary" onClick={exportExcelFile} startIcon={<FileDownloadIcon />}></Button>
            </Tooltip>
        </>
    )
}
